<template>
	<div class="captcha">
		<ParticleBackground />
		<CaptchaCard v-if="isLoggedIn" />
	</div>
</template>

<script>
import ParticleBackground from '@/components/particles/ParticleBackground';
import CaptchaCard from '@/views/captcha/components/CaptchaCard';

export default {
	name: 'CaptchaPage',
	components: {
		ParticleBackground,
		CaptchaCard,
	},
	data: () => ({
		redirect: process.env.VUE_APP_BACKEND + '/api/auth/discord',
	}),
	computed: {
		isLoggedIn() {
			return !!this.$store.getters.user?.id;
		},
		userFetched() {
			return this.$store.getters.userFetched;
		},
	},
	watch: {
		userFetched(to) {
			if (to && !this.isLoggedIn) {
				this.displayLoginModal();
			}
		},
	},
	methods: {
		async displayLoginModal() {
			const opt = {
				text: 'Please log in to complete your captcha.',
				persistent: true,
				centerButtons: true,
				buttons: [
					{
						text: 'Discord Login',
						color: 'primary',
						returns: true,
					},
				],
			};
			await this.$modal(opt).showWarn();
			this.$store.dispatch('setPreLoginUrl', this.$route.path);
			window.location.href = this.redirect;
		},
	},
	mounted() {
		if (this.userFetched && !this.isLoggedIn) {
			this.displayLoginModal();
		}
	},
};
</script>

<style scoped>
.captcha {
	position: relative;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
</style>
