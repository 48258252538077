<template>
	<div>
		<v-card
			v-if="hasCaptcha"
			class="captcha-card pt-8 pb-3 px-5"
			:style="cardBorder"
		>
			<v-card-text class="card-text pb-0">
				<div class="avatar-border" :style="cardBorder">
					<v-avatar size="63">
						<v-img :src="avatarUrl" @error="avatarError" alt="avatar" />
					</v-avatar>
				</div>
			</v-card-text>
			<v-card-title class="center-text welcome-text text-h5 pb-0">
				<div v-html="welcomeText"></div>
			</v-card-title>
			<v-card-text class="center-text text-body-2 pb-0">
				Please click the button below to verify that you're a human.
			</v-card-text>
			<v-card-text class="center-text text-body-2 pt-0">
				<a @click="login">Not you?</a>
			</v-card-text>
			<v-card-actions class="mb-3 welcome-text">
				<LoadingSpinner v-if="isLoading" size="30" />
				<div v-else-if="captchaError">
					Captcha failed. Please reload the page and try again.
				</div>
				<div v-else-if="captchaSuccess">
					I have verified that you're a human!
				</div>
				<vue-hcaptcha
					v-else
					:sitekey="hcaptchaKey"
					theme="dark"
					@verify="onVerify"
					@expired="onExpire"
					@error="onError"
				></vue-hcaptcha>
			</v-card-actions>
			<span class="uuid text-body-2 pt-0">{{ uuid }}</span>
		</v-card>
		<v-card v-else class="captcha-card pt-8 pb-3 px-5" :style="cardBorder">
			<v-card-text class="card-text pb-0">
				<div class="avatar-border" :style="cardBorder">
					<v-avatar size="63">
						<v-img :src="avatarUrl" @error="avatarError" alt="avatar" />
					</v-avatar>
				</div>
			</v-card-text>
			<v-card-title class="center-text welcome-text text-h5 pb-0">
				<div class="welcome-text">
					It seems like you're not a bot, {{ username }}!
				</div>
			</v-card-title>
			<v-card-text class="center-text text-body-2 pb-0">
				You're free to go! c:
			</v-card-text>
			<v-card-text class="center-text text-body-2 pt-0">
				<a @click="login">Not you?</a>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import LoadingSpinner from '@/components/LoadingSpinner';

export default {
	name: 'CaptchaCard',
	components: { VueHcaptcha, LoadingSpinner },
	data: () => ({
		redirect: process.env.VUE_APP_BACKEND + '/api/auth/discord',
		avatarGifError: false,
		avatarPngError: false,
		captchaError: false,
		captchaSuccess: false,
		isLoading: false,
		welcomeTexts: [
			'Hey there |!',
			"I've been expecting you, |...",
			"Well if it isn't |",
			'Nice to see you |!',
			'Thanks for dropping by, |!',
			'Fancy seeing you here, |!',
			'Hey |, do you come here often?',
			'Beep boop, |?',
			'Back so soon |?',
			'Howdy, |!',
			'I spy with my little eye... you, |!',
			"I've been looking for you |!",
			'Have you been cheating |...?',
			'Hewwo |!',
			'I missed you, |!',
			'How was your day, |?',
			"|, here's a reminder to drink water :)",
		],
		buttonTexts: [
			'Click me',
			'Touch me',
			'Boop me',
			'OwO',
			'Beep Boop?',
			'hehe',
			'<3',
			'💩',
			'Meow',
			'This is a button',
			"I'm a button",
			'Jab me',
			'Click me to Verify',
			'Tap me',
			'Push me',
			'Verify',
			'Button.',
			'OK',
			"I'm not a bot",
		],
	}),
	computed: {
		hasCaptcha() {
			return this.user.captcha?.active;
		},
		uuid() {
			return this.user.captcha?.uuid;
		},
		hcaptchaKey() {
			return process.env.VUE_APP_HCAPTCHA_KEY;
		},
		user() {
			return this.$store.getters.user;
		},
		username() {
			return this.user.username + '#' + this.user.discriminator;
		},
		welcomeText() {
			const index = Math.floor(Math.random() * this.welcomeTexts.length);
			return this.welcomeTexts[index].replace(
				'|',
				'<b>' + this.username + '</b>'
			);
		},
		buttonText() {
			const index = Math.floor(Math.random() * this.buttonTexts.length);
			return this.buttonTexts[index];
		},
		avatarUrl() {
			if (!this.user.avatar) {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(this.user.discriminator) % 5
				}.png`;
			} else if (!this.avatarGifError) {
				return this.user.avatarUrlGif;
			} else if (!this.avatarPngError) {
				return this.user.avatarUrlPng;
			}
			return null;
		},
		cardBorder() {
			if (this.captchaSuccess) {
				return 'border: 3px solid var(--v-success-base);';
			} else if (this.captchaError) {
				return 'border: 3px solid var(--v-error-base);';
			}
			return 'border: 3px solid var(--v-primary-base);';
		},
	},
	methods: {
		avatarError() {
			if (!this.avatarGifError) {
				this.avatarGifError = true;
			} else if (!this.avatarPngError) {
				this.avatarPngError = true;
			}
		},
		login() {
			this.$store.dispatch('setPreLoginUrl', this.$route.path);
			window.location.href = this.redirect;
		},
		async onVerify(token) {
			this.isLoading = true;
			try {
				await this.$store.dispatch('verifyCaptcha', token);
				this.captchaSuccess = true;
			} catch (err) {
				console.log(err);
				this.captchaError = true;
			} finally {
				this.isLoading = false;
			}
		},
		onExpire() {
			this.captchaError = true;
		},
		onError() {
			this.captchaError = true;
		},
	},
};
</script>

<style scoped>
.captcha-card {
	display: flex;
	align-items: center;
	flex-direction: column;
	transition: all 0.5s ease;
}

.avatar-border {
	display: flex;
	align-items: center;
	border-radius: 50%;
	width: 69px;
	height: 69px;
	background: var(--v-background-base);
	transition: all 0.5s ease;
}

.card-text {
	position: absolute;
	top: -50px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.welcome-text {
	display: inline-block;
	word-break: break-word;
	text-align: center;
	width: 100%;
}

.center-text {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.uuid {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 0.7rem !important;
	margin-right: -28px;
	margin-bottom: -10px;
	margin-top: -10px;
	color: rgba(255, 255, 255, 0.3);
}
</style>
